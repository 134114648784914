.debt-amount {
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 3.6rem 1.8rem;
  font-size: 6.8rem;
  font-weight: 600;
  box-shadow: 0px 30px 10px -30px #a3a3a3;

  @include breakpoint(laptop) {
    // font-size: 10rem;
    font-size: 4vw;
    // width: 600px;
  }

  @include breakpoint(mobileonly) {
    font-size: 15vw;
  }

  &-reference {
    font-size: 0.198em;
  }
}

.status-background-grey {
  background-color: #2d2d2d;
}

.status-background-green {
  background-color: $rradar-accent-turquoise;
}

.status-background-orange {
  background-color: #ff8d00;
  background-image: linear-gradient(45deg, #da5500, transparent);
}
