.debt-status-timeline {
  margin: 1.8rem 0 0 0;
  flex-wrap: nowrap;
  > div {
    padding: 0.6rem 1.8rem 0.6rem 3rem;
    flex: 1 0 auto;
    background-color: $rradar-black;
    color: #fff;
    font-weight: 500;
    position: relative;

    &:after {
      content: '';
      top: 0;
      right: -19px;
      position: absolute;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 19px solid transparent;
      border-left: 19px solid $rradar-black;
      z-index: 2;
    }
  }
}

.debt-status-info {
  display: inline-block;
  padding-left: 3rem;
  margin-top: 5em;
  border-left: 4px solid $rradar-red;
}

.debt-status-active {
  background: $rradar-accent-turquoise !important;

  &::after {
    border-left-color: $rradar-accent-turquoise !important;
  }
}

.debt-status-active ~ div {
  color: darken($rradar-pearl, 15%) !important;
  background: $rradar-pearl !important;
  &::after {
    border: 0 !important;
  }
}

.debt-status-await {
  background-color: #ff8d00 !important;
  color: $rradar-white;

  &:after {
    border-left-color: #ff8d00 !important;
  }
}

.debt-status-await ~ div {
  color: darken($rradar-pearl, 15%) !important;
  background: $rradar-pearl !important;
  &::after {
    border: 0 !important;
  }
}

.debt-flex-container {
  display: flex;
  flex-wrap: wrap-reverse;
  @media (min-width: 1368px) {
    flex-wrap: nowrap;
  }

  .debt-flex-actions {
    @media (min-width: 1368px) {
      padding: 4rem 0rem 4rem 3rem;
      border-left: 1px solid #fafafa;
      margin-left: 3rem;
      box-shadow: inset 280px 0px 280px -250px #ddd;
    }
  }

  .debt-flex-information {
    flex: 1 0 auto;
    padding: 4rem 0;
    max-width: 100%;
    @media (min-width: 1368px) {
      max-width: 30%;
    }
  }
}
