.radio-button {
  position: relative;
  width: 20px;
  height: 20px;
  display: block;
  z-index: 20;
  margin: 0 10px 10px 10px;

  &-circle {
    fill: #fff;
    transition: 0.15s fill;

    &--checked {
      fill: $rradar-red;
    }
  }
}
