.site-header-rradar-red {
  background-color: $rradar-red;
  height: 5px;
  width: 100%;
  display: block;
  position: relative;
}

.site-header-contents {
  position: relative;
  width: 100%;
  background: linear-gradient(-297deg, #242424, #333 50%, #2d2d2d 0, #2d2d2d);
  flex-direction: row;
  z-index: 3;
  padding: 2rem 0;

  @include breakpoint(laptop) {
    padding: 0.5rem 0;
  }

  &-left {
    text-align: center;
    flex: 1 1 auto;
    text-align: left;

    @include breakpoint(laptop) {
      text-align: left;
    }

    .recover-logo {
      position: relative;
      padding: 7px;
      width: auto;
      height: auto;
      width: 80%;
      cursor: pointer;
      @include breakpoint(laptop) {
        height: 60px;
        width: auto;
      }
    }
  }

  &-right {
    flex: 6 1 auto;
    display: flex;
    justify-content: flex-end;
  }
}

.SignOut {
  position: relative;
  margin-left: 2rem;

  text-align: center;

  @media (max-width: 1024px) {
    top: 55%;

    display: flex;
    flex-direction: column;
  }
  .dark {
    color: darken($rradar-white, 20%);
  }

  .light {
    color: darken($rradar-white, 5%);
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  a {
    color: darken($rradar-white, 10%);
  }

  small {
    padding-left: 4px;
  }
}
