.debt-feedback {
  padding: 0.6rem;
  background: orange;
  border: 1px solid darkorange;
  color: rgba(160, 87, 1, 0.8);
  font-weight: 500;
  margin: 0.3rem 0;
  width: 14.4rem;
}

.reference {
  display: flex;
  top: 20px;

  div {
    padding-right: 2px;
  }

  i {
    color: $rradar-white;
    margin-left: 0.6rem;
  }
}

.error-feedback {
  padding: 0.6rem;
  background: orange;
  border: 1px solid darkorange;
  color: rgba(160, 87, 1, 0.8);
  font-weight: 500;
  margin: 0.3rem 0.6em;
  min-width: 6.6em;
  max-width: 22vw;
}
