.login-container {
  position: relative;
  align-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  background: #f4f4f4;
  img {
    position: relative;
    width: 96px;
    height: 96px;
    left: calc(50% - 48px);
  }

  form {
    button {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
    }
  }

  button {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.sign-up-container {
  flex: 1;
  width: 100%;
  background: $rradar-white;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1em;
}

.sign-up-form {
  display: flex;
}

.sign-up-area {
  position: relative;
  text-align: left;
  align-self: center;
  padding: 0 2rem;

  // @include breakpoint(laptop) {
  //   padding: 0 15rem;
  // }

  header {
    border-left: 4px solid $rradar-red;
    padding-left: 2rem;
  }

  h1 {
    font-size: 3rem;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: 100;
    margin-bottom: 1rem;
  }

  h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 0;
  }
}

.login-button {
  position: relative;
  top: 25%;
}
