.listings-table .table-filters {
  display: none;
  position: absolute;
  z-index: 1;
  min-width: 100%;
  text-align: left;
  background: #626262;
  border: 1px solid #626262;
  border-radius: 0;
  box-shadow: 0 2rem 20px -10px #000;
  top: 6rem;
  max-height: calc(44px * 4);
  overflow-y: scroll;
}

.listings-table .table-filters.is-active {
  display: block;
}

.listings-table .table-filters > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  // font-size: 1.2rem;
  font-weight: 400;
  color: $rradar-white;
  text-transform: none;
  > li {
    padding: 1rem 1.2rem;
    // font-size: 1.25em;
  }
  > li:hover {
    background-color: darken(#626262, 5%);
    cursor: pointer;
  }
}
