.landing-page-contents {
  position: relative;
  flex-wrap: wrap;
  // max-width: 100%;
  // height: 100%;

  @include breakpoint(laptop) {
    flex-wrap: nowrap;
  }

  &-unauthorized {
    width: 100%;

    @media (min-height: 1025px) {
      // width: 100% !important;
      height: 80% !important;
    }

    @include breakpoint(laptop) {
      width: 60%;
    }
  }

  &-authorized {
    width: 100%;
  }

  &-left {
    display: flex;
    // padding: 2rem;
    box-sizing: border-box;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-image: url('/assets/img/landing_page_image.jpg');
    background-size: cover;
    min-height: 50em;


    //This is a bit hacky
    @media (min-height: 1550px) {
      // flex-direction: column !important;
      min-height: 80vh;
    }

    @media (min-height: 1410px) {
      // flex-direction: column !important;
      min-height: 70vh;
    }

    @include breakpoint(mobileonly) {
      padding: 0 0 0 2em;
    }

    @include breakpoint(laptop) {
      padding: 0 0 0 2em;
    }

    @include breakpoint(desktop) {
      padding: 0 0 0 10rem;
    }

    .landing-page-documents-logo {
      position: relative;
      align-content: center;
      width: 300px;
      height: auto;
      padding: 10px;
    }

    .landing-page-left-text {
      position: relative;
      padding: 1rem;
      background-color: transparentize($rradar-black, 0.15);
      text-align: left;
      color: #fff;
      align-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;

      //Bit hacky
      @media (min-height: 1550px) {
        // flex-direction: column !important;
        min-height: 70vh !important;
      }

      @media (min-height: 1410px) and (max-height: 1550px) {
        // flex-direction: column !important;
        min-height: 60vh!important;
      }

      @media (min-height: 1025px) {
        min-height: 50em;
        padding: 6rem;
      }

      @include breakpoint(tabletplus) {
        padding: 1rem;
      }

      @include breakpoint(tablet) {
        padding: 3em 0em 3em 12em;
      }

      @include breakpoint(phablet) {
        padding: 3em 0em 3em 12em;
      }

      @include breakpoint(mobileonly) {
        padding: 3em 0em 3em 1em;
      }

      &::before {
        content: '';
        display: block;
        background-color: transparentize($rradar-black, 0.15);
        width: 100%;
        height: 100%;
        position: absolute;
        left: -100%;
        top: 0;
      }

      h1 {
        font-size: 100%;
        max-width: 70%;
        color: #fff;
        font-weight: 300;
        line-height: 1.2;
        border-left: 4px solid $rradar-red;
        padding-left: 2rem;
        font-size: 350%;
      }

      p {
        text-indent: 0 !important;
        margin: 1rem 0 2rem;
        max-width: 75%;
      }

      .information-block {
        text-indent: 0;
        margin: 0;

        i {
          padding-right: 1em;
        }

        a {
          color: white;
        }
      }
    }
  }
  &-right {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    // @media (min-height: 1025px) {
    //   // width: 100% !important;
    //   height: 22vh;
    // }

    @include breakpoint(laptop) {
      width: 40%;
    }

    input {
      width: 100% !important;
      box-sizing: border-box;
      background-color: #f0f0f0;
      padding: 1.5rem 2rem !important;
    }

    .red-button {
      margin: 0 !important;
    }
  }
}

.dashboard-add-debt-span {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  color: $rradar-white;
  background: $rradar-red;
  box-shadow: inset 0 -4px 0 0 #b60000;

  > span {
    font-weight: 400;
    margin-left: 2rem;
  }
}
