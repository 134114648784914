.primary-button {
  @include button($colour-primary-green, $rradar-white);
}

.red-button {
  @include button($rradar-red, $rradar-white);
}

.secondary-button {
  @include button(
    $rradar-white,
    $colour-dark-grey,
    $colour-dark-grey,
    $rradar-charcoal,
    $rradar-white
  );
}

.cancel-button {
  @include button(
    $rradar-white,
    $colour-dark-grey,
    $rradar-white,
    $rradar-charcoal,
    $rradar-white,
    $rradar-charcoal
  );
}

.white-button {
  @include button(
    transparent,
    $rradar-white,
    $rradar-white,
    $rradar-white,
    $rradar-red,
    $rradar-white,
    1px
  );
  box-shadow: inset 0px -4px 0px 0px rgba(255, 255, 255, 0.4);
  transition: all 0.2s ease-in-out;
}

.button-area-buttons {
  & > * {
    margin-right: 0.7em;
  }

  & > *:last-child {
    margin-right: 0;
  }
  justify-content: flex-end;
}
