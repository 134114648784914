ul {
  padding-left: 0.96rem;

  li {
    line-height: 2;
  }
}

a:link {
  color: $rradar-red;
}

a:hover {
  color: $rradar-accent-red;
}

a:visited {
  color: $rradar-red;
}

label {
  position: relative;
  font-weight: 400;
  font-family: 'Roboto';
  margin-bottom: 0.9rem;
  display: block;
  margin-top: 2.4rem;
}

label.required {
  &::before {
    content: '*';
    color: #c00;
    position: relative;
    margin-left: 0rem;
    line-height: 0;
    top: 0.3rem;
    font-size: 1.2rem;
    margin-right: 0.6rem;
  }
}

input:not([type='file']):not([type='checkbox']):not([type='submit']) {
  padding: 1.2rem 1.2rem;
  border: none;
  font-size: 16px;
  background-color: $rradar-pearl;
  display: block;
  box-sizing: border-box;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;

  &::placeholder {
    color: #9b9b9b;
  }

  &:disabled {
    opacity: 0.8;
  }
}

input[type='checkbox'] {
  width: 2vh;
  height: 2vh;
}

input[type='number']:before {
  content: '£';
  background: $rradar-red;
  color: $rradar-white;
}

select {
  width: 100%;
  padding: 16px 20px;
  border: none;

  background-color: #f1f1f1;
}

// h1,
// h2 {
//   color: $rradar-charcoal;
//   font-family: 'Roboto', sans-serif;
//   font-weight: 300;
//   margin-top: 0;
// }

// h3,
// h4,
// h5 {
//   color: $rradar-charcoal;
//   font-family: $font-subheadline;
//   font-weight: 300;
// }

strong {
  font-weight: 400;
}

p {
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
  margin-top: 0.6rem;
}

.site-container {
  position: inherit;
  height: 100%;
  background-color: $rradar-white;
  // font-size: 1.6em;
  margin: 0;

  &-fixed {
    @include breakpoint(mobileonly) {
      position: fixed;
    }
  }

  .site-body {
    overflow-x: hidden;
    min-height: 70vh !important;
  }

  table {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    border-collapse: collapse;
  }

  thead {
    background-color: #555;
    color: $rradar-white;
    margin: 0;
    display: none;
    @include breakpoint(laptop) {
      display: table-header-group;
    }

    a {
      color: $rradar-white;
      margin-left: 0.6rem;
    }
  }
  tbody {
    tr {
      display: block;
      padding: 1.2rem;
      text-align: left;

      @include breakpoint(laptop) {
        display: table-row;
        height: 60px;
        padding: 0rem;
      }

      &:nth-child(even) {
        background-color: $rradar-pearl;
      }
    }
  }

  th {
    padding-left: 1.2rem;
    text-align: left;
    height: 60px;
    position: relative;
  }

  td {
    padding-left: 1.2rem;
    margin: 0;
    display: block;
    @include breakpoint(laptop) {
      display: table-cell;
    }
  }

  // .container {
  //   margin: 0 auto 2rem;
  //   padding: 0 2rem;
  //   box-sizing: border-box;
  //   min-width: 90%;
  //   @include breakpoint(desktop) {
  //     padding: 0 10rem;
  //   }
  // }

  .site-contents {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .site-header-container {
    top: 0;
    padding: 0rem 1.2rem;
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(desktop) {
      padding: 0 6rem;
    }
  }
}

.panel {
  padding: 2.4rem;
  background-color: #fafafa;
  border: 1px solid #fcfcfc;
  margin: 1.2rem 0 0 0;
  box-shadow: 0px 30px 10px -30px #a3a3a3;

  font-size: 100%;
  z-index: 1;
}

.split_panel_description {
  h4,
  h3 {
    margin-top: -1px;
    margin-bottom: 0;
    font-weight: 500;
  }

  .left {
    margin-right: 1.8rem;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
  overflow-x: hidden;
}

.ReactModal__Overlay {
  z-index: 21 !important;
  overflow-y: auto;
}

.ReactModal__Content {
  position: relative;
  padding: 3rem !important;
  bottom: auto !important;
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.8em;

  @include breakpoint(mobileonly) {
    width: 100%;
  }
  @include breakpoint(phablet) {
    width: 100%;
  }
}

.disabled-button {
  pointer-events: none;
  background-color: $rradar-red;
  cursor: default !important;
  opacity: 0.5;
}

.listings-table--undefined,
.listings-table-- {
  text-align: end;
  padding-right: 10px;
}

.listings-table--Amount {
  text-align: right !important;
}

.form-input {
  border-radius: 3px;
  display: flex;
  // height: 1.92em;
  margin: 0.6em;
  min-width: 8.4em;
  max-width: 30em;

  // input {
  //   padding: 0 2rem !important;
  // }

  @media (max-width: 1600px) {
    flex-direction: column;
    margin-bottom: 2.4em;

    label,
    .form-input-title {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  label,
  .form-input-title {
    display: flex;
    align-items: center;
    background: #555;
    color: white;
    min-width: 10em;
    padding: 0.6em;
    margin: 0;
    max-width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  input,
  .form-input-contents {
    display: flex;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #f4f4f4;
    height: 100%;
    width: 100%;

    strong {
      margin-left: 1.2em;
    }
  }

  &--with-tag {
    &-contents {
      min-height: 39px;
      max-height: 52px;
      width: 100%;
    }
    input {
      // min-width: 25em;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
    }
    .form-input-tag {
      background: #1598a4;
      border-bottom-right-radius: 3px;
      color: white;
      // height: 58px;
      padding: 0.8rem;
      width: 5.15em;
      display: flex;
      align-items: center;
      font-size: 13px;
    }
    .form-input-unit {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: white;
      background-color: #333;
      width: 1.8em;
    }
  }

  .react-datepicker-wrapper {
    height: 100%;
    width: 100%;
    .react-datepicker__input-container {
      height: 100%;
    }
    .react-datepicker-ignore-onclickoutside {
      height: 100%;
    }
    input {
      height: 100%;
    }
  }
}

textarea {
  border: none;
  background-color: #f4f4f4;
  min-height: 4rem;
}

.form-panel {
  min-width: 13.8rem;
  // max-width: 36vw;
  padding: 0.6em;
  margin: 0.6em;
  &-label {
    margin: 0.6em;
  }
  &-coloured {
    background: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
  &-centered {
    align-items: center;
  }
}

.form-input-container {
  // max-width: 32vw;
  margin: 0.6em;
  label {
    margin: 0;
  }

  &-title {
    display: flex;
    align-items: center;
    background: #555;
    color: white;
    min-width: 5.4em;
    padding: 0.6em;
    margin: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 99%;
  }

  &-contents {
    justify-content: space-evenly;
    background-color: #f4f4f4;
  }

  textarea {
    padding: 0.6em;
    resize: none;
  }
}

.interest-rate-title {
  width: 96%;
}

.hidden_field {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.__floater__arrow {
  display: none;
}

.text-button {
  font-size: 0.95rem;
  background-color: #c00;
  padding: 0.6rem 1.2rem;
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  box-shadow: inset 0 -4px 0 0 #b60000;
}

.login-grey-button {
  background-color: #d3d3d3;
  border: 0;
  cursor: pointer;
}

.modal-textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 6em;
  max-height: 24em;
  padding: 0.6em;
}

.isPointer {
  cursor: pointer;
}

.modal-header {
  justify-content: space-between;
  h3 {
    margin: 0.15em 0;
  }
}

.modal-button-area {
  justify-content: flex-end;
}

.nav-info {
  display: none;
}
