.debt-action-button {
  width: 6em;
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0.96em;
  // background: #1298a4;
  background: #5e5e5e;
  color: #fff;
  border-radius: 0.1em;
  box-shadow: 3px 3px 6px 0px #bababa;

  svg {
    transform: scale(2);
    margin: 1em;
    font-size: 0.6rem;
  }

  p {
    margin: 0;
  }

  &-container {
  }

  &-disabled {
    opacity: 0.3;
  }

  &-active {
    cursor: pointer;
    transition-duration: 0.3s;
    &:hover {
      // background-color: #cc0000;
      background-color: #424242;
      box-shadow: 1px 1px 6px 0px #ccc;
    }
  }
}
