.dynamic-cards {
  display: flex;
  flex-flow: row wrap;
}

.dynamic-card {
  background-color: $rradar-white;
  padding: 3rem;
  border: 1px solid $rradar-pearl;
  margin: 1rem;
  > span {
    display: block;
  }

  &--amount {
    font-size: 2rem;
    font-weight: 500;
  }
}