.radio-container {
  padding: 1em;
  flex-wrap: wrap;

  label {
    display: flex;
    flex-direction: row;
    margin: 0.5em 0;

    .radio-option {
      text-emphasis: none;
      margin-right: 10px;
      min-width: 8em;
    }

    input {
      margin-right: 20px;
    }
  }
}
