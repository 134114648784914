.loading-spinner-overlay {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  display: flex;
  justify-content: center;

  .spinner-container {
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  button {
    width: 120px;

    position: relative;
    top: 5em;
  }

  i {
    font-size: 5em;
    position: absolute;
  }
}

.is-loading {
  @include is-loading;
}
