.track-limit {
  background-color: #8e0000;
  color: $rradar-white;
  position: relative;
  flex-basis: 100%;
  width: 12.6rem;
  height: 320px;
  // justify-content: center;

  @include breakpoint(laptop) {
    flex-basis: 50%;
    flex: 1 1 0px;
  }

  &-numbers {
    padding: 3rem 0;

    @include breakpoint(laptop) {
      text-align: left;
      flex-basis: 50%;
      padding: 3rem 1.8rem;
    }
    @include breakpoint(phablet) {
      padding: 3rem 1.8rem;
    }
    @include breakpoint(mobileonly) {
      text-align: left;
      flex-basis: 50%;
      padding: 3rem 1.8rem;
    }
  }

  .limit-sub-title {
    color: $rradar-white;
  }

  h3 {
    color: $rradar-white;
    padding: 0;
    margin: 10px;
    font-size: 3rem;
  }

  h4 {
    padding: 0;
    margin-left: 10px;
    font-size: 1.5rem;
  }

  h5 {
    padding: 0;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 0;
    font-size: 1.2rem;
    color: $rradar-white;
  }

  .add {
    cursor: pointer;
  }
}

.track-limit.flex-column::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #8e0000;
  right: 100%;
}

@media (max-width: 1023px) {
  .track-limit.flex-column::after {
    content: '';
    background-color: #8e0000;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 100%;
    top: 0;
  }
}
