.edit-debtor {
  &-header {
    box-shadow: inset 0 -8px 6px -9px #ccc;
    padding: 1em;
    justify-content: space-evenly;

    h1 {
      // width: 650px;
      font-size: 3em;
      align-items: center;
      margin: 0;
    }

    &-contents {
      width: 50%;
      align-items: center;

      h3 {
        margin: 0;
      }
    }

    &-contents::before {
      content: "";
      display: block;
      background: #c00;
      height: 95px;
      border-radius: 3px;
      width: 3px;
      margin-right: 0.75em;
    }
  }
}
