.search-bar-container {
  flex: 2 0 auto;
  flex-wrap: nowrap;
  align-items: center;
  width: 32rem;
}

.search-icon {
  color: #777;
  margin: 2rem;
}

.search-bar {
  background-color: $colour-dark-grey !important;
  color: #bbbbbb;
  font-weight: 300;
  padding: 1rem 2rem !important;
  font-family: "Roboto";
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:focus {
    background-color: darken(#353535, 5%) !important;
    outline: none !important;
    color: lighten(#bbbbbb, 5%);
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.1);
    &::placeholder {
      color: lighten(#bbbbbb, 5%);
    }
  }
}
