.collapsible {
  &-container {
    margin: 2em 0 2em 0;
    overflow: hidden;
  }
  &-header {
    // justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
      width: 180px;
    }

    .firstDemand-debts,
    .secondDemand-debts,
    .requested-debts {
      width: 360px;
    }
  }
}

.line {
  position: absolute;
  width: 100%;
}

.collapsible-button {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  font-size: 75%;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.collapsible-arrow {
  height: 1.5rem;
  margin-left: 1em;
  font-size: 0.7rem;
  color: $rradar-charcoal;

  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.line:after {
  position: relative;
  content: ' ';
  display: block;
  height: 2px;
  background: $rradar-pearl;
  width: 100%;
  top: 2em;
}

.rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.2s linear;
}

.rotatedown {
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: all 0.2s linear;
}

.collapsed {
  visibility: hidden;
  opacity: 0.1;
  transition: all 0.2s linear;
  height: 10px;
}
.expanded {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s linear;
}
