.track-owed {
  position: relative;

  text-align: left;
  background: $rradar-pearl;
  padding: 3rem;
  box-sizing: border-box;
  text-align: left;
  color: $rradar-charcoal;
  width: 100%;
  height: 320px;

  @include breakpoint(laptop) {
    text-align: left;
    flex-basis: 50%;
    padding: 3rem;
  }

  h5 {
    padding: 0;
    margin-top: 0.6rem;
    margin-bottom: 0px;
    font-size: 1.2rem;
    color: inherit;
  }

  h3 {
    padding: 0;
    margin-top: 0px;
    font-size: 2.1rem;
    color: inherit;
    font-weight: 600;
    margin-bottom: 0.6rem;
    text-align: left;

    @include breakpoint(laptop) {
      text-align: left;
      font-size: 4.2rem;
    }
  }

  .track-owed-recovered {
    color: inherit;
    text-align: left;
    @include breakpoint(laptop) {
      text-align: left;
    }
  }
}

.radioSelect {
  width: 50px;
  position: absolute;
  left: 100%;
  bottom: 40%;

  .radio-button-circle {
    cursor: pointer;
  }
}

.Inactive {
  position: absolute;
  width: 83.4%;
  opacity: 0;
  transition: all 0.5s ease-in;
}

.Active {
  position: absolute;
  width: 83.4%;
  opacity: 1;
  transition: all 0.5s ease-in;
  z-index: 10;
}

.track-owed-slider {
  display: flex;
  height: 100%;
  align-items: center;

  &:after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
}

.track-owed.flex-column::after {
  content: '';
  background-color: $rradar-pearl;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 100%;
  top: 0;
}
@media (max-width: 1023px) {
  .track-owed.flex-column::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $rradar-pearl;
    right: 100%;
    top: 0;
  }
}

.no-bank-details {
  @include breakpoint(laptop) {
    text-align: left;
    flex-basis: 100% !important;
    padding: 3rem;
  }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #1298a4 !important;
    right: 100%;
    top: 0;
  }
}
