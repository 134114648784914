.form-panel--60 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpoint(laptop) {
    // max-width: 60%;
  }

  @include breakpoint(mobileonly) {
    width: 100%;
  }

  > div {
    flex: 1 0 auto;
  }
}
