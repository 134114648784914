.dashboard {
  position: relative;
  height: 100%;

  &-body {
    margin: 0 auto;
    padding: 0 2em 4em 2em;
    height: auto;
    overflow-x: hidden;

    @include breakpoint(mobileOnly) {
      padding: 0em !important;
    }

    &-welcome {
      padding: 10px;
      h4 {
        text-align: center;
        margin: 5px;
      }

      h5 {
        text-align: center;
        margin: 0;
      }
    }
  }

  &-add-debt {
    height: 50px;
    width: 50px;
  }

  &-add-debt-span {
    display: inline-flex;
    flex-direction: row;
    cursor: pointer;

    h4 {
      margin-top: 10px;
    }
  }
}

.dashboard-header {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 1em 0;
  margin-top: 1em;
}
