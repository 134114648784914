.banner-orange {
  box-shadow: inset 0px 0px 0px 1px #ff8c00;
  background-color: #ffe8cc;
  .banner-text {
    color: #b76f18;
  }

  .banner-left-icon {
    background-color: #ff8c00;
  }
}

.banner-blue {
  box-shadow: inset 0px 0px 0px 1px #4f6669;
  background-color: #b4dce0;
  .banner-text {
    color: #4f6669;
  }

  .banner-left-icon {
    background-color: #1298a4;
  }
}

.banner-container {
  text-align: center;

  margin: 0 0 1rem 0;
  // color: black !important;

  .banner-content {
    .banner-text {
      // color: $rradar-charcoal;

      text-align: left;
      padding: 0.9rem;

      strong {
        display: block;
      }
    }
    .banner-left-icon {
      display: flex;
      font-size: 1.2rem;
      justify-content: center;
      color: white;
      width: 1.2rem;
      align-items: center;
      min-width: 4.2rem;

      i {
        position: relative;
        display: block;
        text-align: center;
      }
    }
  }

  h2 {
    font-weight: bolder;
    color: black !important;
  }

  p {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
  }
}
