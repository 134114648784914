.profileitem {
  display: flex;

  &_slot {
    display: flex;
    align-items: center;
    margin-left: 3rem;
    font-size: 100%;
    line-height: 1.2;
    color: $rradar-white !important;

    &--image {
      border-radius: 50%;
      max-width: 30px;
      margin-right: 1rem;
    }

    &--icon {
      margin-right: 1rem;
      font-size: 1.6rem;
      width: 30px;
      height: 30px;
    }
    @media (max-width: 1024px) {
      &--icon {
        display: none;
      }
    }
    &--header {
      display: block;
      font-weight: 500;
    }
  }
}

.slot_header {
  &--interaction,
  &--name {
    cursor: pointer;
  }
}
