.add-debt-wizard {
  &-container {
    padding: 0 1.2rem;
    min-height: inherit;
    .header-stage-container {
      position: absolute;
      width: 100%;
      padding: 2em 0em;

      left: 0;
      // background-color: #f4f4f4;
      // box-shadow: 0 5px 5px -5px #333;
      box-shadow: 0px 6px 62px -50px #3a3a3a;
    }
  }

  &-page {
    padding: 6rem 5vw 0 5vw;
    min-height: inherit;
  }

  &-form {
    min-height: inherit;
    &-contents {
      padding: 3rem 7.5vw;

      @include breakpoint(mobileonly) {
        padding: 0;
      }
      // margin-bottom: 8em;
    }
  }
}

.link_navigation {
  // box-shadow: 0 -5px 5px -5px #333;
  margin-top: auto;
  box-shadow: 0px 50px 62px 6px #3a3a3a;
  position: relative;
  left: -10%;
  width: 120%;
  overflow: none;
  // bottom: 380px;
  justify-content: space-between;
  padding: 1.2rem 9rem;
  box-sizing: border-box !important;
  align-items: center;

  a {
    margin: 0 1rem;
  }
  // background-color: #f4f4f4;

  // @media (min-width: 788px) {
  //   bottom: 280px;
  // }

  // @include breakpoint(tablet) {
  //   bottom: 345px !important;
  //   padding: 1.2rem 0.6rem;
  // }

  @include breakpoint(phablet) {
    left: -12%;
    width: 124%;
    padding: 1.2rem 0.6rem;
  }

  @include breakpoint(mobileonly) {
    left: -14%;
    width: 128%;
    padding: 1.2rem 0.6rem;
  }

  .nav-button-grey {
    background-color: white;
    padding: 1rem 2rem;
    border: 2px solid #555;
    border-radius: 0px;
    color: #111 !important;
    text-decoration: none !important;
    height: fit-content;
    min-width: 4em;
    text-align: center;
    &:hover {
      background-color: #555;
      color: white !important;
      text-decoration: none;
    }
  }

  .nav-button-red {
    background-color: $rradar-red;
    padding: 1rem 2rem;
    border: #c00 2px solid;
    border-radius: 0px;
    color: $rradar-white !important;
    text-decoration: none !important;
    height: fit-content;
    min-width: 4em;
    text-align: center;

    &:hover {
      background-color: darken($rradar-red, 5%);
      text-decoration: none;
    }
  }
}

.original-amount-input {
  span {
    background-color: #555;
    display: flex;
    color: white;
    font-size: 2em;
    padding: 0 0.2em;
    align-items: center;
  }

  &-pounds {
    input {
      width: 6.6rem;
      text-align: right;
    }
  }

  &-pence {
    input {
      width: 4.8rem;
    }
  }
}

.interest-rate-input {
  span {
    background-color: #555;
    display: flex;
    color: white;
    font-size: 2em;
    padding: 0 0.2em;
    align-items: center;
  }
}
