.next-steps-list {
  font-size: 120%;
  max-width: 100%;

  .whats-next-stage-container {
    margin: 1.5em 2em;
    .content {
      margin-top: 0.5em;
      font-size: 80%;
    }
  }
}

.actions-section {
  width: 100%;
}

.status-identifier {
  font-style: italic;
}

.company_name_click {
  cursor: pointer;
  color: $rradar-red;
  text-decoration-line: underline;
}

.title-icon {
  padding: 0.5em 0 0.5em 0;
  align-items: flex-start;
  svg {
    width: 1em !important;
    margin-right: 1em;
  }
}

.debt-description {
  margin-left: 3.7rem;
}
