.admin-view-demands-page {
  display: flex;
  align-items: center;
  justify-content: center;

  .document {
    position: relative;
    height: 100%;
    margin-left: 7em;
    z-index: 1;
  }

  .prev-button {
    left: 0%;
  }

  .next-button {
    right: 0%;
  }

  @include breakpoint(mobileonly) {
    height: 30em;
  }

  object {
    @include breakpoint(mobileonly) {
      width: 300px;
      height: 500px;
    }
  }

  .pdf-document-container {
    width: 70%;
    height: 55em;
    margin: 1em;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
  }

  .outliner {
    width: 30em;
    height: 55em;
    position: relative;

    border: black 1px solid;

    &-actions {
      background-color: $rradar-black;
      color: white;
      height: 6em;
      text-align: center;
      padding: 1em;
      justify-content: space-evenly;

      // button {
      //   margin: 0.2em 1em 1em 1em;
      // }
    }

    &-index {
      margin-left: 5em;
      text-align: left;
      overflow-y: auto;
      // height: 80%;
      justify-content: space-around;
      div {
        margin: 0.75em 0;
        svg {
          margin-left: 5em;
        }
      }
    }

    &-page-controls {
      position: absolute;
      width: 100%;
      background-color: $rradar-black;
      justify-content: space-around;
      bottom: 0;

      p {
        text-align: center;
        margin-top: 1em;
      }
    }
  }
}
