.tab-buttons {
  margin-left: 0.6em;
  margin-top: 1.9em;
  color: #999999;
  background-color: $rradar-pearl;

  .tab-button {
    display: block;
    padding: 12px 2rem;
    font-size: 0.9rem;
    font-family: Roboto, sans-serif;
    border: none;
  }
}

.active_tab {
  cursor: default;
  background-color: #555555;
  color: white;
}

.inactive_tab {
  cursor: pointer;
  background-color: $rradar-pearl;
  &:hover {
    background-color: #555555;
    color: $rradar-white;
  }
}

.toDo {
  font-size: 1.2em;
  border: solid 1px #ff8c00;
  text-align: center;
  width: 80%;

  @include breakpoint(mobileonly) {
    width: 100%;
  }
}

.company-details-container {
  flex-wrap: wrap;
  margin-bottom: 1.2em;
  // justify-content: end;
}
