.debtor-selected {
  &-container {
    background-color: #f8f8f8;
    width: max-content;
    height: 2.4em;
    color: #777;
    text-align: left;
    border: 1px solid #ccc;
    padding: 0.6rem 1.2rem;
    position: relative;
    top: calc(123px + 0.6rem);
    left: 0;
    cursor: pointer;
    margin: 0 12.5vw;

    &-hidden {
      visibility: hidden;
      opacity: 0.1;
      // transition: all 0.1s linear;
      position: absolute;
    }

    &-visible {
      visibility: visible;
      opacity: 1;
      transition: all 0.1s linear;
    }
  }

  &-contents {
    font-size: 0.657em;
  }

  &-button {
    text-decoration: underline;
    /* padding: .8em; */
    margin-top: 0.12rem;
    font-size: 0.48em;
  }
}
