.headed-textarea-input {
  height: 12.5em;
  // width: 15em;
  padding: 1em;
  display: flex;
  justify-content: center;

  &-container {
    justify-content: space-between;
  }

  &-icon {
    text-align: center;
    font-size: 2.7em;
    margin-bottom: 0.7em;
  }

  // input {
  //   width: 95%;
  //   height: 40%;
  // }
  textarea {
    height: 100%;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 1em;
    border: none;
    background-color: $rradar-pearl;
  }
}
