.protocol-tile {
  height: 10em;
  width: 14em;
  align-items: center;
  justify-content: center;
  padding: 1em;
  cursor: pointer;
  transition: 0.4s;

  &-image {
    padding: 0.65em;
    border-radius: 3em;
    font-size: 2.7em;
    width: 2.5em;
    height: 2.5em;
    transition: 1s;
    box-sizing: border-box !important;

    &-selected {
      border: 5px solid $rradar-accent-turquoise;
    }

    &-unselected {
      border: 5px solid #ffffff;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $rradar-accent-turquoise;
    }
  }

  &-text {
    justify-content: center;
    align-items: center;
    color: $rradar-accent-turquoise;
    font-size: 1.4em;
    text-align: center;
  }

  &-container {
    align-items: baseline;
    justify-content: space-between;

    &-disabled {
      pointer-events: none;
      opacity: 0.75;
    }
  }

  &-unselected {
    opacity: 0.2;
  }
}
