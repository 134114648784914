.modal {
  font-size: 1.4rem;

  h3 {
    font-size: 2.5rem;
  }

  p {
    margin-bottom: 2rem;
  }
}
