.quick-actions {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;

  @media (min-width: 1026px) {
    justify-content: flex-end;
    margin-left: 2rem;
  }

  .quick-action-buttons {
    position: relative;
    text-decoration: none;
    padding: 1rem 2rem;
    text-align: center;
    color: $rradar-white;
    margin: 0px;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    transition: all 0.1s ease-in-out;

    @media (min-width: 1026px) {
      width: auto;
      margin-left: 1rem;
    }

    &:hover {
      background-color: darken($rradar-red, 5%);
      border-color: $rradar-red;
      color: $rradar-white;
    }
  }
  &-profile {
    flex-grow: 1;
    display: flex;
    align-items: end;
    @media (min-width: 1026px) {
      flex-grow: 0;
      margin-left: 1em;
    }
  }
}

.active_header {
  cursor: default !important;
  background-color: $rradar-charcoal !important;
  border-color: $rradar-white !important;
  color: $rradar-white !important;
  border-width: 1px !important;
}

.active_dashboard_header {
  cursor: pointer !important;
  background-color: $rradar-charcoal !important;
  border-color: $rradar-white !important;
  color: $rradar-white !important;
  border-width: 1px !important;

  &:hover {
    background-color: darken($rradar-red, 5%) !important;
    border-color: $rradar-red !important;
    color: $rradar-white !important;
  }
}
