.navigation-drawer {
  position: fixed;
  background: linear-gradient(-297deg, #242424, #333 50%, #2d2d2d 0, #2d2d2d);
  max-width: 280px;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 99;
  padding: 1rem 0rem 5rem 2rem;
  box-sizing: border-box !important;
  transform: translateX(280px);
  transition: all 0.1s ease-in-out;
  font-weight: 400;

  &-open {
    transform: translateX(0px);
    display: flex;
    flex-direction: column;
  }

  svg {
    // font-size: 200%;
    color: $rradar-white;
  }

  .quick-actions {
    margin-left: 0;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    div:nth-child(2) {
      // flex-direction: column;
      // text-align: center;
    }
  }

  .quick-action-buttons {
    border-width: 0px;
  }
}

.customer-items {
  display: flex;
  flex-direction: column;
  align-self: self-start;
  @media (min-width: 1026px) {
    display: flex;
    flex-direction: row;
    align-self: auto;
  }
}
