.legal-action-outlook {
  padding: 3em 5em;

  h2 {
    margin-top: 1em;
  }

  &-button {
    position: absolute;
  }
}
