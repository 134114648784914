.trackingbox-container {
  position: relative;
  align-content: center;
  margin-bottom: 2.4rem;

  // @include breakpoint(desktop) {
  //   width: 75%;
  // }

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  button {
    font-size: 1.2rem;
    padding: 0.9rem 1.8rem;
  }
}

.no-bank-details {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em !important;
  color: white !important;
  button {
    align-content: center;
    // @include breakpoint(mobileonly) {
    //   width: 50%;
    // }
  }

  .bank-warning {
    font-size: 5em;

    @include breakpoint(mobileonly) {
      font-size: 3em;
    }
  }

  &::after {
    content: '';
    background-color: #1298a4 !important;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 100%;
    top: 0;
  }
}

.limit-sub-title {
  color: $rradar-charcoal;
  // font-family: "Poppins", sans-serif;
  // text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
}

.limit-title {
  font-weight: 600;
  font-size: 2.1rem;
  @include breakpoint(laptop) {
    font-size: 4.2rem;
  }
}
