.demand-list-item {
  align-items: start;
  padding: 1.2rem;
  display: flex;
  flex-flow: column nowrap;
  // cursor: pointer;

  .flex-row {
    width: 100%;
  }

  &-info {
    margin-left: 1.2rem;
    display: flex;
    flex-flow: column nowrap;

    &--meta {
      display: flex;
      flex-flow: column nowrap;
    }

    h3 {
      margin: 0;
    }
  }

  &--action {
    margin-left: auto;
    align-self: center;
    font-size: 80%;
    text-decoration: underline;
    cursor: pointer;
  }

  &--content {
    padding: 0 1.8rem;
    margin-top: 1.8rem;
    margin-left: 3.6rem;
    border-left: 5px solid #f3f3f3;
  }
}

.demand-list-icon {
  .icon {
    color: rgb(255, 255, 255);
  }
}

i.demand-list-item-timeago {
  font-size: 80%;
  font-weight: 400;
  color: #a0a0a0;
}

.demand-list {
  // background-color: #fafafa;
  background-color: #ffffff;
  margin-bottom: 1.2rem;
  border: 1px solid #fcfcfc;

  header {
    position: relative;
    background-color: $rradar-charcoal;
    color: rgb(255, 255, 255);
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    justify-content: space-between;
  }

  h2 {
    color: inherit;
    margin: 1.2rem;
    font-size: 1.2rem;
    font-weight: 300;
  }

  &-icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
    svg,
    i {
      color: $rradar-silver;
      font-size: 1.2rem;
    }
  }
}

.noPointer {
  cursor: default !important;
}
