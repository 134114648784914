.jurisdiction-button {
  width: 10em;
  padding: 1em;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-align: center;
  opacity: 0.5;
  transform: translateY(0px);
  &-active {
    transform: translateY(-1px);
    opacity: 1;
  }

  &-image {
    img {
      width: 5em;
    }
  }
  &-text {
    margin-top: 1em;
  }
}
