.debtor_tile {
  width: 35rem;
  min-height: 10rem;
  border: $rradar-pearl 3px solid;
  margin: 1em;
  transition: 0.4s;
  cursor: pointer;

  .protocol_identity {
    background-color: $rradar-pearl;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    padding: 0.3em;
    i {
      font-size: 1.8em;
    }
  }

  .debtor_details {
    justify-content: space-evenly;
    padding: 0.3em;
    p {
      margin: 0;
    }
  }

  &_container {
    // box-shadow: 0 -5px 5px -5px #333;
    box-shadow: 0 5px 5px -5px #333;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2em;
    // padding: 1em;
    min-height: 20rem;
    max-height: 40rem;
    overflow: auto;
    transition: 0.4s;
  }
}

.inactive_debtor {
  opacity: 0.55;
  &:hover {
    border: #555 3px solid;
    opacity: 1;

    .protocol_identity {
      background-color: #555;
      color: white !important;
    }
  }
}

.active_debtor {
  border: $rradar-accent-turquoise 3px solid;

  .protocol_identity {
    background-color: $rradar-accent-turquoise;
    color: white !important;
  }
}
