.tooltip {
  position: relative;
  display: inline-block;
  cursor: help;
}

.tooltip .tooltiptext {
  visibility: hidden;

  width: 300px;
  bottom: 100%;

  background-color: #555555;
  color: #e6e6e6;
  text-align: center;
  font-weight: 600;
  padding: 2em;
  border-radius: 0.5rem;

  position: absolute;
  z-index: 1000000000000000000;

  > p {
    margin: 0;
  }

  @include breakpoint(laptop) {
    width: 300px !important;
    left: -135px !important;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: #555555;
      border-bottom: 0;
      margin-left: -10px;
      margin-bottom: -10px;
    }
  }

  @include breakpoint(phablet) {
    width: 150px;
    left: 10px;
    &:after {
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }

  @include breakpoint(mobileonly) {
    width: 150px;
    left: 10px;
    &:after {
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }
  @include breakpoint(tablet) {
    width: 150px;
    left: -60px;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: #555555;
      border-bottom: 0;
      margin-left: -10px;
      margin-bottom: -10px;
    }
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;

  -webkit-animation-name: tooltip;
  -webkit-animation-duration: 0.3s;
  animation-name: tooltip;
  animation-duration: 0.3s;
}

@-webkit-keyframes tooltip {
  0% {
    bottom: 0%;
    opacity: 0;
  }
  100% {
    bottom: 100%;
    opacity: 1;
  }
}

@keyframes tooltip {
  0% {
    bottom: 0%;
    opacity: 0;
  }
  100% {
    bottom: 100%;
    opacity: 1;
  }
}

//This is terrible css as I have just copied to try and fix the tooltips.  Not sure what happened - "BUT THIS IS A QUICK FIX" - so sort it out

.hint {
  position: relative;
  display: inline-block;
  cursor: help;
}

.hint .hinttext {
  visibility: hidden;
  bottom: 100%;
  background-color: #555555;
  color: #e6e6e6;
  text-align: center;
  font-weight: 600;
  padding: 2em;
  border-radius: 0.5rem;

  position: absolute;
  z-index: 1000;

  > p {
    margin: 0;
    min-width: 100% !important;
  }
  @include breakpoint(laptop) {
    width: 300px !important;
  }

  @media (max-width: 37.5em) {
    position: fixed;
    width: 150px;
    bottom: auto;
    top: 50%;
    left: 50%;
    margin-top: -25%;
  }

  @include breakpoint(tablet) {
    width: 200px;
  }
  @include breakpoint(phablet) {
    width: 150px;
    bottom: auto;
    top: 50%;
    left: 50%;
    margin-top: -25%;
    margin-left: -50px;
  }
}

.hint:hover .hinttext {
  visibility: visible;

  @include breakpoint(tablet) {
    -webkit-animation-name: hint;
    -webkit-animation-duration: 0.3s;
    animation-name: hint;
    animation-duration: 0.3s;
  }
}

@-webkit-keyframes hint {
  0% {
    bottom: 0%;
    opacity: 0;
  }
  100% {
    bottom: 100%;
    opacity: 1;
  }
}

@keyframes hint {
  0% {
    bottom: 0%;
    opacity: 0;
  }
  100% {
    bottom: 100%;
    opacity: 1;
  }
}
