.terms-and-conditions-content {
  .terms-of-service-body {
    line-height: 1.8em;
    font-size: 1.2em;
    h1,
    h2 {
      margin: 20px 0px;
    }

    h3 {
      margin: 10px 0px;
    }

    .definitions-table {
      tr {
        td:first-child {
          width: 8vw;
        }
      }

      margin: 1vh 0;
    }

    ol {
      list-style-type: none;
      counter-reset: item;
      margin: 0;
      padding: 0;
    }

    ol > li {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
    }

    ol > li:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.6em;
    }

    li ol > li {
      margin: 0;
    }

    li ol > li:before {
      content: counters(item, ".") " ";
    }

    .fixed-sum-table {
      td,
      th {
        border: 1px solid black;
      }
    }
  }
}
